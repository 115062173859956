body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

td {
  border: 1px solid #ddd;
  padding: 8px;
}

.App {
  text-align: center;
  padding: 60px;
}

.AccountInformation {
  margin-top: 25px;
}
.AccountInformation .notification {
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
}

.wrapper {
  margin-top: 35px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  text-align: center;
  gap: 45px;
}

.box {
  margin: 0 !important;
}
.box__title {
  color: #4a4a4a;
  margin: 0 !important;
}
.box__body {
  padding-top: 32px;
  padding-bottom: 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message {
  margin: 0 !important;
}
.message-body {
  padding: 12px 20px !important;
  word-break: break-all;
}

.field.is-horizontal {
  align-items: center;
}
.field.is-horizontal .field-label {
  flex-basis: 70px;
  flex-grow: unset;
  flex-shrink: unset;
  margin-right: 15px;
}
.field.is-horizontal .field-label .label {
  font-weight: 500;
  color: #4a4a4a;
}
